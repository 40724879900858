
.margin-table[data-v-2efcbac6] {
  margin-left: 30px;
  margin-right: 30px;
}
.blue-underline[data-v-2efcbac6] {
  text-decoration: underline;
  color: #409EFF;
  cursor: pointer; /* 添加鼠标悬停时的指针样式 */
}
